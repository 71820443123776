body {
  margin: 0;
  padding: 0;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 2px; /* Set the width of the scrollbar */
}

thead th {
  border-right: none;
}
tbody td {
  border-right: none;
  border-top: none;
}
input[type="date"]::-webkit-datetime-edit {
  color: black; /* Apply black color to all parts (day, month, year) */
}

input[type="date"]::-webkit-datetime-edit-fields-wrapper {
  color: black;
}
input[type="date"]::-webkit-datetime-edit-year-field,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field {
  color: black;
}
/* @media print {
  body * {
    visibility: hidden;
  }

  #printable-area, #printable-area * {
    visibility: visible;
  }
  #printable-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  button {
    display: none;
  }
} */